import {useCallback, useEffect, useMemo} from "react";
import lunr from 'lunr';

/**
 * https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
 * @param str
 * @returns {*}
 */
export const removeAccents = (str) => str ? str.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : null;
/**
 *  https://multizone.cz/devfeed/adding-search-to-your-site-with-javascript-9ac4291a.html
 */

const useLunr = (documents, ref, fields) => {
    const index =  useMemo(() => {
        return documents ? lunr(function () {
            this.ref(ref);
            if (fields && documents) {
                fields.forEach((field) => {
                    this.field(field);
                })
                documents.forEach((doc) => {
                    const document = {
                        [ref]: doc[ref],
                        ...fields.reduce((acc, field) => {
                            acc[field] = removeAccents(doc[field]);
                            return acc;
                        }, {})
                    };
                    this.add(document);
                });
            }
        }): null;
    }, [documents, ref, fields]);

    return useCallback((str) => {
        if (str && str.length > 2) {
            let terms = removeAccents(str).split(' ').join('* +');
            return index.search(`+${terms}*`).map((item) => {
                console.log(item);
                return documents.find((document) => document[ref] === item.ref);
            });
        }
        return documents;
    }, [index, ref, documents]);
};

export default useLunr;