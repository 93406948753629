import React, {useCallback, useContext, useMemo} from 'react';
import {Switch, Route} from 'react-router-dom';
import Homepage from "./Containers/Homepage";
import SaleCustomers from "./Containers/SaleCustomers";
import Invoices from "./Containers/Invoices";
import Stock from "./Containers/Stock";
import {useLocation, Redirect} from "react-router-dom";
import './App.css';
import OrderProvider from "./order/OrderProvider";
import SaleProducts from "./Containers/SaleProducts";
import Order from "./Containers/Order";
import useAuth from "./auth/useAuth";
import {Spinner} from "react-bootstrap";
import OrderIssued from "./Containers/OrderIssued";
import AppNavbar from "./Components/Layout/AppNavbar";
import AppContainer from "./Components/Layout/AppContainer";

const PAGES = {
    sklad: {label: 'Sklad', path: '/sklad'},
    faktury: {label: 'Faktury', path: '/faktury'},
    prodejZakaznik: {label: 'Zákazník', path: '/prodej/zakaznik'},
    prodejProdukt: {label: 'Zboží', path: '/prodej/zbozi'},
    objednavka: {label: 'Košík', path: '/prodej/objednat'},
    prodejDokoncen: {label: 'Doklad vystaven', path: '/objednano/:invoice'},
    homepage: {label: 'Zahradnictví Škoda', path: '/'}
}

function App() {
    const location = useLocation();
    const {isReady, needRedirect, isFetching} = useAuth();

    if (needRedirect) {
        return <Redirect to={location.pathname}/>
    }

    if (!isReady) {
        return <div className='text-center m-5'><h1>{isFetching ? 'Vyčkejte prosím' : 'Ups..'}</h1><Spinner animation="border"/></div>
    }

    return (
        <OrderProvider>
            <AppNavbar pages={PAGES}/>
            <AppContainer>
                <Switch>
                    <Route path={PAGES.sklad.path}>
                        <Stock/>
                    </Route>

                    <Route path={PAGES.faktury.path}>
                        <Invoices/>
                    </Route>

                    <Route path={PAGES.prodejZakaznik.path}>
                        <SaleCustomers pages={PAGES}/>
                    </Route>

                    <Route path={PAGES.prodejProdukt.path}>
                        <SaleProducts pages={PAGES}/>
                    </Route>

                    <Route path={PAGES.objednavka.path}>
                        <Order pages={PAGES}/>
                    </Route>

                    <Route path={PAGES.prodejDokoncen.path}>
                        <OrderIssued/>
                    </Route>

                    <Route path="/">
                        <Homepage pages={PAGES}/>
                    </Route>
                </Switch>
            </AppContainer>
        </OrderProvider>
    );
}

export default App;