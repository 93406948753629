import {useCallback, useContext, useEffect} from "react";
import AuthContext from "./AuthContext";

const useAuth = () => {
    const auth = useContext(AuthContext);

    return {
        username: auth.username,
        accessToken: auth.accessToken,
        isAuthenticated: auth.isAuthenticated,
        needRedirect: auth.needRedirect,
        isFetching: auth.isFetching,
        isReady: auth.isAuthenticated && !auth.needRedirect && !auth.isExpired
    }
}
export default useAuth;