import {useCallback, useContext, useEffect, useReducer, useRef} from 'react';
import {ModelContext} from "./ModelContext";
import promiseWrapper from "./promiseWrapper";

const createRequestState = (actions, type) => {
    switch (type) {
        case actions.FETCH_INIT:
            return {
                isFetching: true,
                isSuccess: false,
                isFailed: false
            };

        case actions.FETCH_SUCCESS:
            return {
                isFetching: false,
                isSuccess: true,
                isFailed: false
            };

        case actions.FETCH_FAILURE:
            return {
                isFetching: false,
                isSuccess: false,
                isFailed: true,
            };
        default:
            return {};
    }
};

const createActionNames = (path) => {
    const prefix = (Array.isArray(path) ? path.join('/') : path) + '/';
    return {
        FETCH_INIT: `${prefix}FETCH_INIT`,
        FETCH_SUCCESS: `${prefix}FETCH_SUCCESS`,
        FETCH_FAILURE: `${prefix}FETCH_FAILURE`,
        FETCH_RESET: `${prefix}FETCH_RESET`,
    }
};

const actionNames = createActionNames('save');

const initialState = {
    result: null,
    documentType: null,
    isFetching: false,
    isSuccess: false,
    isFailed: false,
};

const reducer = (state, action) => {
    switch (action.type) {
        case actionNames.FETCH_INIT:
            return {
                ...state,
                result: null,
                documentType: action.documentType,
                ...createRequestState(actionNames, action.type)

            };

        case actionNames.FETCH_SUCCESS:
            return {
                ...state,
                result: action.payload,
                ...createRequestState(actionNames, action.type)

            };

        case actionNames.FETCH_FAILURE:
            return {
                ...state,
                result: action.payload,
                ...createRequestState(actionNames, action.type)
            };

        default:
            return state;
    }
};

const dispatchCallback = async (callback, args, dispatch) => {
    dispatch({type: actionNames.FETCH_INIT});
    const {isSuccess, data} = await promiseWrapper(callback(), ...args);
    if (isSuccess) {
        dispatch({type: actionNames.FETCH_SUCCESS, payload: data, document});
    } else {
        dispatch({type: actionNames.FETCH_FAILURE, payload: data, document});
    }
};

export const useModel = (callback) => {

    const callbackRef = useRef(callback);

    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    const model = useContext(ModelContext);
    const [state, dispatch] = useReducer(reducer, initialState);

    const process = useCallback((...args) => {
        dispatchCallback(() => callbackRef.current(model), args, dispatch);
    }, [callbackRef, dispatch, model]);

  //  useHookToLoading(`${state.documentType}-save`, state);

    return {
        state,
        process
    }
};

export default useModel;