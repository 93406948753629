import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter} from "react-router-dom";
import AuthProvider from "./auth/AuthProvider";
import {createAuthStorage} from "./auth/authStorage";
import * as axios from "axios";
import {createModel} from "./model";
import ModelProvider from "./model/ModelProvider";

const hostName = window.location.hostname.startsWith('app.') ? window.location.hostname.substr(4) : window.location.hostname;
const baseURL = process.env.REACT_APP_BACKEND || window.location.protocol + "//api." + hostName;

const axiosInstance = axios.create({
    baseURL
});

const model = createModel(axiosInstance, baseURL, localStorage);

const authStorage = createAuthStorage(localStorage);

const token = authStorage.getAccessToken();

if (token) {
   model.setAuthorizationToken(token);
}

ReactDOM.render(
    <React.StrictMode>
        <ModelProvider model={model}>
            <BrowserRouter>
                <AuthProvider storage={authStorage} setAuthorizationToken={model.setAuthorizationToken}>
                    <App/>
                </AuthProvider>
            </BrowserRouter>
        </ModelProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
