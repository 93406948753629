import React, {useContext} from "react";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import {Col} from "react-bootstrap";
import {OrderContext} from "../order/OrderContext";

const OrderOverview = ({children}) => {
    const {price} = useContext(OrderContext);

    return  <div className='fixed-bottom bg-light'>
        <Container>
            <Row>
                <Col className='pt-3 pb-2 text-right text-sm-left' xs={12} sm={6}>
                    Celkem za: <strong>{price}</strong> Kč
                </Col>
                <Col className='pt-1'>
                    <div className='float-right'>{children}</div>
                </Col>
            </Row>
        </Container>
    </div>
};

export default OrderOverview;