import React, {useCallback, useState} from "react";
import {Row, Button, Col, FormControl, InputGroup} from "react-bootstrap";
import handleClick from "../utils/handleClick";
import {Check, Trash, X} from "react-bootstrap-icons";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import {isNumber} from "@mouseover/js-utils/src";

const CurrentEvidenceItem = ({evidenceItem, onDismiss, onConfirm}) => {
    const {name, amount, unitPrice} = evidenceItem;
    const [currentAmount, setCurrentAmount] = useState(amount);

    const handleChange = useCallback((event) => {
        if (event.target) {
            setCurrentAmount(parseInt(event.target.value));
        }
    }, []);

    const handleChangeAmount = useCallback((acc) => {
        setCurrentAmount((current) => {
            current = isNumber(current) ? current : 0;
            return current + acc >= 0 ? current + acc : current;
        });
    }, [setCurrentAmount]);

    const handleDismissClick = useCallback(() => {
        if(onDismiss) {
            onDismiss(evidenceItem);
        }
    }, [onDismiss, evidenceItem]);

    const handleConfirmClick = useCallback(() => {
        if(onConfirm) {
            onConfirm({...evidenceItem, amount: currentAmount});
        }
    }, [onDismiss, evidenceItem, currentAmount]);

    const handleDeleteClick = useCallback(() => {
        if(onConfirm) {
            onConfirm({...evidenceItem, amount: 0});
        }
    }, [onDismiss, evidenceItem, currentAmount]);

    return <Row>
        <Col xs={12} md={12} sm={12} className='pt-2 pb-2'><Row><Col>{name}</Col><Col xs={4} className='text-right'>{unitPrice} Kč/ks</Col></Row></Col>
        <Col xs={12} md={6} sm={12}>
            <InputGroup className="mb-2">
                <InputGroup.Prepend>
                    <Button variant='light' onClick={handleClick(() => handleChangeAmount(-5))}>-5</Button>
                    <Button variant='light' onClick={handleClick(() => handleChangeAmount(-1))}>-</Button>
                </InputGroup.Prepend>
                <FormControl type="number" min={0} value={currentAmount} onChange={handleChange} />
                <InputGroup.Append>
                    <Button variant='light' onClick={handleClick(() => handleChangeAmount(1))}>+</Button>
                    <Button variant='light' onClick={handleClick(() => handleChangeAmount(5))}>+5</Button>
                </InputGroup.Append>
            </InputGroup>
        </Col>
        <Col xs={12} md={3} className='pt-2 pb-2'>
           Celkem: {unitPrice * currentAmount} Kč
        </Col>
        <Col xs={12} md={3} className='text-right'>
            <div className='float-right'>
                <Button onClick={handleClick(handleDeleteClick)} variant="danger" className='mr-2'><Trash /></Button>
                <Button onClick={handleClick(handleDismissClick)} variant="warning" className='mr-2'><X /></Button>
                <Button onClick={handleClick(handleConfirmClick)} variant="success"><Check /></Button>
            </div>
        </Col>
    </Row>
}

export default CurrentEvidenceItem;