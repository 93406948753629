import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import useModel from "../model/useModel";
import {OrderContext} from "../order/OrderContext";
import Products from "../Components/Products";
import {Redirect} from "react-router-dom";
import {Spinner} from "react-bootstrap";
import CurrentEvidenceItem from "../Components/CurrentEvidenceItem";
import CustomerBar from "../Components/CustomerBar";
import useSearch from "../search/useSearch";
import useFilter from "../search/useFilter";
import ProductsFilter from "../Components/ProductsFilter";

const SEARCH_CONFIG = {ref: 'id', fields: ['name']};

const SaleProducts = ({pages}) => {
    const {process: loadProducts, state: {result, isSuccess: productsLoaded}} = useModel((model) => model.product.get);

    const {
        customer,
        searchText,
        setProduct,
        currentEvidenceItem,
        dismissCurrentEvidenceItem,
        confirmCurrentEvidenceItem,
        hideSearchProduct,
        filter
    } = useContext(OrderContext);

    const [confirmed, setConfirmed] = useState();
    const {products, productGroups} = useMemo(() => result || {}, [result]);

    let visibleProducts = useSearch({
        documents: products,
        searchText,
        config: SEARCH_CONFIG
    }); // predava se jako konstanta aby se nevytvarel config porad znova pri kazdem pruchodu

    visibleProducts = useFilter({documents: visibleProducts, filter})

    // zajisti vypnuti vyhledavani pri opusteni komponenty
    useEffect(() => hideSearchProduct, [hideSearchProduct]);

    // nacteni produktu, refresh pri zmene zakaznika
    useEffect(() => {
        if (customer) {
            loadProducts(customer.id);
        }
    }, [customer, loadProducts]);

    // vyber produktu
    const handleSelect = useCallback((selected) => setProduct(selected), [setProduct]);

    // potvrzene vlozeni produktu
    const handleConfirm = useCallback((evidenceItem) => {
        confirmCurrentEvidenceItem(evidenceItem);
        setConfirmed(true);
    }, [confirmCurrentEvidenceItem, setConfirmed]);

    if (confirmed) {
        // byl vybran a pridan prodkut presmerujeme do kosiku
        return <Redirect to={pages.objednavka.path}/>
    }

    if (!customer) {
        // neni zakaznik presmerujem na vyber zakaznika
        return <Redirect to={pages.prodejZakaznik.path}/>
    }

    return <>
        <CustomerBar filters={productGroups}>
            <ProductsFilter className='mt-1' filters={productGroups}/>
        </CustomerBar>

        {!currentEvidenceItem && productsLoaded ? <Products items={visibleProducts} onSelect={handleSelect}/> : (!productsLoaded ?
            <Spinner animation={'border'}/> : null)}
        {currentEvidenceItem ? <CurrentEvidenceItem evidenceItem={currentEvidenceItem} onDismiss={dismissCurrentEvidenceItem}
                                                    onConfirm={handleConfirm}/> : null}
    </>;
};

export default SaleProducts;