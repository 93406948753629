import React, {useCallback} from "react";
import Table from "react-bootstrap/Table";
import handleClick from "../utils/handleClick";

const Products = ({items, onSelect}) => {
    const handleProductClick = useCallback((product) => {
        if (onSelect) {
            onSelect(product);
        }
    }, [onSelect]);
    return <Table striped bordered hover size="sm">
        <thead className='sticky-top'>
        <tr>
            <th>Název</th>
            <th className='text-right'>Cena/ks</th>
            <th className='text-right'>Skladem</th>
        </tr>
        </thead>
        <tbody>
        {items ? items.map((product, index) => {
            const {name, unitPrice, inStock} = product;
            return <tr onClick={handleClick(() => handleProductClick(product))} key={index}>
                <td>{name}</td>
                <td className='text-right'>{unitPrice}</td>
                <td className='text-right'>{inStock}</td>
            </tr>
        }) : null}
        </tbody>
    </Table>;
};

export default Products;