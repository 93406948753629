const isInvalidResponse = (res) => (!res.data);
const mapResponse = (res) => res.data;

const get = (axios, path, params) => axiosRequest(axios,{
    url: path,
    params
});

const post = (axios, path, params, data) => axiosRequest(axios,{
    url: path,
    params,
    method: 'POST',
    data
});

const axiosRequest = async (axiosInstance, requestParameters) => {
    return axiosInstance(requestParameters)
        .then(res => {
            if (isInvalidResponse(res)) {
                const e = Error('Invalid response');
                e.data = res;
                throw e;
            }
            return mapResponse(res);
        });
};


export const createModel = (axios, baseURL, storage) => {
    return {
        baseURL: baseURL,
        downloadURL: baseURL + '/invoice/download',
        product: {
            get: (customerId) => get(axios, '/product', {customerId})
        },
        customer:  {
            get: () => get(axios, '/address-book')
        },
        order: {
            save: (order) => post(axios, '/order', {}, order)
        },
        invoice: {
            get: () => get(axios, '/invoice')
        },
        user: {
            authenticate: (credentials) => {
                return post(axios, credentials.refresh_token ? '/authenticate/refresh-token' : '/authenticate/api-key', {}, credentials);
            }
        },
        storeState: (key, state) => {
            storage.setItem(key, JSON.stringify(state));
        },
        loadState: (key) => {
            const state = storage.getItem(key);
            try {
                return state ? JSON.parse(state) : null;
            } catch (e) {
                console.log('Error loading state!', e);
                return null;
            }
        },
        setAuthorizationToken: (token) => axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    }
};

