import useLunr from "./useLunr";
import {useEffect, useState} from "react";

const useSearch = ({documents, searchText, config}) => {
    const search = useLunr(documents, config.ref, config.fields);
    const [filtered, setFiltered] = useState(documents);

    useEffect(() => {
        if (documents) {
            setFiltered(search(searchText));
        }
    }, [documents, search, searchText]);

    return filtered;
}

export default useSearch;