import {useLocation} from "react-router-dom";
import React, {useContext} from "react";
import {OrderContext} from "../../order/OrderContext";
import {LinkContainer} from "react-router-bootstrap";
import Button from "react-bootstrap/Button";
import {CardList, CartPlus, PersonCheck} from "react-bootstrap-icons";
import ButtonLabel from "./ButtonLabel";

const SignalButtons = ({pages}) => {
    const location = useLocation();
    const {customer, evidenceItems} = useContext(OrderContext);
    return <>
        <LinkContainer to={pages.prodejZakaznik.path}>
            <Button variant={'outline-info'} className='mr-2'><PersonCheck/> <ButtonLabel>{pages.prodejZakaznik.label}</ButtonLabel></Button>
        </LinkContainer>
        {customer ? <LinkContainer to={pages.prodejProdukt.path}><Button variant={'outline-info'} className='mr-2'><CartPlus/> <ButtonLabel>{pages.prodejProdukt.label}</ButtonLabel></Button></LinkContainer> : null}
        {evidenceItems.length > 0 ? <LinkContainer to={pages.objednavka.path}><Button variant={'outline-info'} className='mr-2'><CardList/> <ButtonLabel>{pages.objednavka.label}</ButtonLabel></Button></LinkContainer> : null}
    </>
};

export default SignalButtons;