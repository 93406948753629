const clearStorage = (storage) => {
    storage.removeItem('accessToken');
    storage.removeItem('refreshToken');
    storage.removeItem('expireAt');
    storage.removeItem('userId');
    storage.removeItem('username');
};

const setData = (storage, data) => {
    storage.setItem('accessToken', data.accessToken);
    storage.setItem('refreshToken', data.refreshToken);
    storage.setItem('expireAt', data.expireAt);
    storage.setItem('userId', data.userId);
    storage.setItem('username', data.username);
}

const persistInLocalStorage = (storage, body) => {
    setData(storage, {
        accessToken: body.access_token,
        refreshToken: body.refresh_token,
        expireAt: new Date(new Date().getTime() + body.expires_in * 1000),
        userId: body.userId,
        username: body.username
    })
};

const EMPTY = {
    accessToken: null,
    refreshToken: null,
    username: null,
    expireAt: null,
    userId: null
}

// TODO: pokud je expirovany tak ho nema vracet...
export const createAuthStorage = (storage) => {
    return {
        getAccessToken: () => storage.getItem("accessToken"),
        getRefreshToken: () => storage.getItem("refreshToken"),
        getUserName: () => storage.getItem("username"),
        getExpireAt: () => storage.getItem("expireAt"),
        getUserId: () => storage.getItem("userId"),
        clearStorage: () => clearStorage(storage),
        persistAuthResponse: (body) => persistInLocalStorage(storage, body),
        setData: (data) => setData(storage, data),
        getData: () => {
            const expireAt = storage.getItem("expireAt");
            if (expireAt) {
                const now = new Date();
                const expireAtDateTime = expireAt ? new Date(expireAt) : null;
                return {
                    isExpired: expireAtDateTime < now,
                    accessToken: storage.getItem("accessToken"),
                    refreshToken: storage.getItem("refreshToken"),
                    username: storage.getItem("username"),
                    expireAt: expireAtDateTime,
                    userId: storage.getItem("userId"),
                }
            }
            return EMPTY;
        }
    }
}
