import React, {useContext} from "react";
import Button from "react-bootstrap/Button";
import {LinkContainer} from "react-router-bootstrap";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import {Basket, FileEarmarkText, Grid3x2Gap, X} from "react-bootstrap-icons";
import Row from "react-bootstrap/Row";
import {Col} from "react-bootstrap";
import {OrderContext} from "../order/OrderContext";
import {Redirect} from "react-router-dom";
import handleClick from "../utils/handleClick";

const HomepageButton = ({to, children, icon}) => <LinkContainer to={to}>
    <Button size='lg' variant='outline-dark'>
        {children}
    </Button>
</LinkContainer>;

const Homepage = ({pages}) => {
    const {evidenceItems, reset} = useContext(OrderContext);

    if (evidenceItems.length === 0) {
        return <div className='mt-5'>
            <Row>
                <Col lg={{span: 4, offset: 4}} sm={{span: 2, offset: 2}}>
                    <ButtonGroup className="justify-content-between">
                        <HomepageButton to={pages.objednavka.path}>
                            <Basket size={50}/>
                            Prodej
                        </HomepageButton>
                        <HomepageButton to={pages.faktury.path}>
                            <FileEarmarkText size={50}/>
                            {pages.faktury.label}
                        </HomepageButton>
                        <HomepageButton to={pages.sklad.path}>
                            <Grid3x2Gap size={50}/>
                            {pages.sklad.label}
                        </HomepageButton>
                    </ButtonGroup>
                </Col>
            </Row>
        </div>;
    }

    return <div className='mt-5'>
        <Row>
            <Col lg={{span: 4, offset: 4}} sm={{span: 2, offset: 2}}>
                <ButtonGroup className="justify-content-between">
                    <HomepageButton to={pages.objednavka.path}>
                        <Basket size={50}/>
                        <br />Pokračovat
                    </HomepageButton>
                    <Button size='lg' variant='danger' onClick={handleClick(reset)}>
                        <X size={50}/>
                        <br /> Ukončit
                    </Button>
                </ButtonGroup>
            </Col>
        </Row>
    </div>
};

export default Homepage;