import {useCallback, useContext, useEffect, useMemo, useReducer, useState} from "react";
import {ModelContext} from "../model/ModelContext";

const EVIDENCE_ITEM = {
    name: null,
    unitPrice: null,
    priceList: null,
    amount: 1
};

const ACTIONS = {
    SET_CUSTOMER: 'SET_CUSTOMER',
    ADD_PRODUCT: 'ADD_PRODUCT',
    ADD_EVIDENCE_ITEM: 'ADD_EVIDENCE_ITEM',
    SET_PRODUCT: 'SET_PRODUCT',
    SET_CURRENT_EVIDENCE_ITEM: 'SET_CURRENT_EVIDENCE_ITEM',
    CONFIRM_PRODUCT: 'CONFIRM_PRODUCT',
    CONFIRM_CURRENT_EVIDENCE_ITEM: 'CONFIRM_CURRENT_EVIDENCE_ITEM',
    DISMISS_CURRENT_EVIDENCE_ITEM: 'DISMISS_CURRENT_EVIDENCE_ITEM',
    RESET: 'RESET',
    SHOW_SEARCH_CUSTOMER: 'SHOW_SEARCH_CUSTOMER',
    SHOW_SEARCH_PRODUCT: 'SHOW_SEARCH_PRODUCT',
    HIDE_SEARCH_CUSTOMER: 'HIDE_SEARCH_CUSTOMER',
    HIDE_SEARCH_PRODUCT: 'HIDE_SEARCH_PRODUCT',
    SET_SEARCH_TEXT: 'SET_SEARCH_TEXT',
    SET_FILTER: 'SET_FILTER'
}

const INITIAL_STATE = {
    customer: null,
    evidenceItems: [],
    currentEvidenceItem: null,
    price: 0,
    visibleSearchCustomer: false,
    visibleSearchProduct: false,
    searchText: null,
    filter: null
};

const productToItem = (product) => ({
    ...EVIDENCE_ITEM,
    name: product.name,
    unitPrice: product.unitPrice,
    priceList: product.id,
    amount: 0
});

function addProductToEvidenceItems(stateEvidenceItems, product) {
    // clone evidence items (dont modify old state)
    const evidenceItems = [...stateEvidenceItems];

    // check for existing
    const existingIndex = evidenceItems.findIndex((item) => item.priceList === product.id);

    if (existingIndex !== -1) {
        evidenceItems[existingIndex] = {...evidenceItems[existingIndex], amount: evidenceItems[existingIndex].amount + 1};
    } else {
        // add new evidence item
        evidenceItems.unshift(productToItem(product));
    }
    return evidenceItems;
}

function addEvidenceItemToEvidenceItems(stateEvidenceItems, evidenceItem) {
    // clone evidence items (dont modify old state)
    const evidenceItems = [...stateEvidenceItems];

    // check for existing
    const existingIndex = evidenceItems.findIndex((item) => item.priceList === evidenceItem.priceList);

    if (existingIndex !== -1) {
        if (evidenceItem.amount > 0) {
            evidenceItems[existingIndex] = {...evidenceItem};
        } else {
            evidenceItems.splice(existingIndex, 1);
        }
    } else if (evidenceItem.amount > 0) {
        // add new evidence item
        evidenceItems.unshift({...evidenceItem});
    }
    return evidenceItems;
}

const mergeWithExistingIfExists = (evidenceItems, evidenceItem) => {
    const existingIndex = evidenceItems.findIndex((item) => item.priceList === evidenceItem.priceList);
    if (existingIndex !== -1) {
        return {...evidenceItems[existingIndex]};
    } else {
        return {...evidenceItem};
    }
}

const priceWithEvidenceItems = (evidenceItems) => {
    return {
        evidenceItems,
        price: evidenceItems.reduce((acc, evidenceItem) => acc + (evidenceItem.unitPrice * evidenceItem.amount), 0)
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.ADD_PRODUCT:
            return {
                ...state,
                ...priceWithEvidenceItems(addProductToEvidenceItems(state.evidenceItems, action.product))
            };

        case ACTIONS.ADD_EVIDENCE_ITEM:
            return {
                ...state,
                ...priceWithEvidenceItems(addEvidenceItemToEvidenceItems(state.evidenceItems, action.evidenceItem)),
                visibleSearchProduct: false
            };

        case ACTIONS.SET_CUSTOMER:
            return {
                ...INITIAL_STATE,
                customer: action.customer,
                visibleSearchCustomer: false
            };

        case ACTIONS.SET_PRODUCT:
            return {
                ...state,
                currentEvidenceItem: mergeWithExistingIfExists(state.evidenceItems, productToItem(action.product))
            };

        case ACTIONS.SET_CURRENT_EVIDENCE_ITEM:
            return {
                ...state,
                currentEvidenceItem: mergeWithExistingIfExists(state.evidenceItems, action.evidenceItem)
            };

        case ACTIONS.DISMISS_CURRENT_EVIDENCE_ITEM:
            return {
                ...state,
                currentEvidenceItem: null
            };

        case ACTIONS.CONFIRM_CURRENT_EVIDENCE_ITEM:
            return {
                ...state,
                currentEvidenceItem: null
            }

        case ACTIONS.RESET:
            return {
                ...INITIAL_STATE
            }

        case ACTIONS.SHOW_SEARCH_PRODUCT:
            return {
                ...state,
                visibleSearchProduct: true
            }

        case ACTIONS.SHOW_SEARCH_CUSTOMER:
            return {
                ...state,
                visibleSearchCustomer: true
            }

        case ACTIONS.HIDE_SEARCH_PRODUCT:
            return {
                ...state,
                visibleSearchProduct: false,
                searchText: INITIAL_STATE.searchText
            }

        case ACTIONS.HIDE_SEARCH_CUSTOMER:
            return {
                ...state,
                visibleSearchCustomer: false,
                searchText: INITIAL_STATE.searchText
            }

        case ACTIONS.SET_SEARCH_TEXT:
            return {
                ...state,
                searchText: action.searchText
            }

        case ACTIONS.SET_FILTER:
            return {
                ...state,
                filter: action.filter
            }

        default:
            return state;
    }
};

const useReducerWithSavedState = (key, reducer, initialState) => {
    const model = useContext(ModelContext);
    const initialStateMemo = useMemo(() => model.loadState(key) || initialState, [initialState, model, key]);
    const [state, dispatch] = useReducer(reducer, initialStateMemo);
    useEffect(() => {
        model.storeState(key, state);
    }, [state, model, key]);
    return [state, dispatch];
}
const useOrder = () => {
    const [state, dispatch] = useReducerWithSavedState('order', reducer, INITIAL_STATE);

    const setCustomer = useCallback((customer) => {
        dispatch({type: ACTIONS.SET_CUSTOMER, customer});
    }, [dispatch]);

    const addProduct = useCallback((product) => {
        dispatch({type: ACTIONS.ADD_PRODUCT, product});
    }, [dispatch]);

    const setProduct = useCallback((product) => {
        dispatch({type: ACTIONS.SET_PRODUCT, product});
    }, [dispatch]);

    const setCurrentEvidenceItem = useCallback((evidenceItem) => {
        dispatch({type: ACTIONS.SET_CURRENT_EVIDENCE_ITEM, evidenceItem});
    }, [dispatch]);

    const dismissCurrentEvidenceItem = useCallback((evidenceItem) => {
        dispatch({type: ACTIONS.DISMISS_CURRENT_EVIDENCE_ITEM, evidenceItem});
    }, [dispatch]);

    const confirmCurrentEvidenceItem = useCallback((evidenceItem) => {
        dispatch({type: ACTIONS.ADD_EVIDENCE_ITEM, evidenceItem});
        dispatch({type: ACTIONS.DISMISS_CURRENT_EVIDENCE_ITEM, evidenceItem});
    }, [dispatch]);

    const toggleCustomerSearch = useCallback(() => {
        dispatch({type: state.visibleSearchCustomer ? ACTIONS.HIDE_SEARCH_CUSTOMER : ACTIONS.SHOW_SEARCH_CUSTOMER});
    }, [dispatch, state.visibleSearchCustomer]);

    const toggleProductSearch = useCallback(() => {
        dispatch({type: state.visibleSearchProduct ? ACTIONS.HIDE_SEARCH_PRODUCT : ACTIONS.SHOW_SEARCH_PRODUCT});
    }, [dispatch, state.visibleSearchProduct]);

    const reset = useCallback(() => {
        dispatch({type: ACTIONS.RESET});
    }, [dispatch]);

    const setSearchText = useCallback((searchText) => {
        dispatch({type: ACTIONS.SET_SEARCH_TEXT, searchText});
    }, [dispatch]);

    const setFilter = useCallback((filter) => {
        dispatch({type: ACTIONS.SET_FILTER, filter});
    }, [dispatch]);

    const hideSearchCustomer = useCallback(() => {
        dispatch({type: ACTIONS.HIDE_SEARCH_CUSTOMER});
    }, [dispatch]);

    const hideSearchProduct = useCallback(() => {
        dispatch({type: ACTIONS.HIDE_SEARCH_PRODUCT});
    }, [dispatch]);


    return {
        ...state,
        setCustomer,
        addProduct,
        setProduct,
        setCurrentEvidenceItem,
        dismissCurrentEvidenceItem,
        confirmCurrentEvidenceItem,
        reset,
        toggleCustomerSearch,
        toggleProductSearch,
        setSearchText,
        hideSearchCustomer,
        hideSearchProduct,
        setFilter
    }
}

export default useOrder;