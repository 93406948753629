import React, {useCallback, useContext, useEffect, useRef} from "react";
import {Col, FormControl, Row} from "react-bootstrap";
import {OrderContext} from "../order/OrderContext";
const SearchBar = () => {
    const {setSearchText, searchText} = useContext(OrderContext);
    const handleChange = useCallback((event) => {
        if (event.target) {
            setSearchText(event.target.value);
        }
    }, [setSearchText]);
    const controlRef = useRef();

    useEffect(() => {
        if (controlRef && controlRef.current) {
            controlRef.current.focus();
        }
    }, [controlRef]);

    return  <FormControl
        className={'form-control-sm'}
        type='text'
        placeholder='Zadejte hledany text'
        defaultValue={searchText}
        ref={controlRef}
        onChange={handleChange} />;
}

export default SearchBar;