const handleClick = (callback, ...args) => (event) => {
    if (event && typeof event.preventDefault === 'function') {
        event.preventDefault();
    }

    if (callback) {
        callback.apply(null, args);
    }
};

export default handleClick;

