import React, {useContext, useMemo} from "react";
import {OrderContext} from "../../order/OrderContext";
import Container from "react-bootstrap/Container";

const AppContainer = (props) => {
    const {customer, visibleSearchCustomer, visibleSearchProduct} = useContext(OrderContext);
    const className = useMemo(() => {
        let visibleCount = 0;

        if(customer) {
            visibleCount += 1;
        }

        if(visibleSearchProduct) {
            visibleCount += 1;
        }

        if(visibleSearchCustomer) {
            visibleCount += 1;
        }
        if (visibleCount === 0) {
            return 'fixed-top-navbar-content';
        } else if (visibleCount === 1) {
            return 'fixed-top-2navbar-content';
        } else {
            return 'fixed-top-3navbar-content';
        }
    }, [visibleSearchCustomer, visibleSearchProduct, customer]);

    return <Container {...props} className={className}/>
}

export default AppContainer;