import React, {useEffect} from "react";
import useModel from "../model/useModel";
import Products from "../Components/Products";
import {Spinner} from "react-bootstrap";

const Stock = () => {
    const {process: loadProducts, state: {result, isSuccess: productsLoaded}} = useModel((model) => model.product.get);
    useEffect(() => {
        loadProducts();
    }, [loadProducts]);
    return productsLoaded ? <Products items={result.products} /> : (!productsLoaded ? <Spinner animation={'border'} /> : null);
};

export default Stock;