import React from "react";
import {Button} from "react-bootstrap";
import useInvoiceLink from "./useInvoiceLink";

const InvoiceDownloadButton = ({id, children, ...props}) => <Button
    href={useInvoiceLink(id)}
    target='_blank'
    {...props}
>{children}</Button>;

export default InvoiceDownloadButton;