import React, {useEffect} from "react";
import Table from "react-bootstrap/Table";
import useModel from "../model/useModel";
import DateTime from "../Components/DateTime";
import {FileEarmarkArrowDown} from "react-bootstrap-icons";
import useAuth from "../auth/useAuth";
import InvoiceDownloadButton from "../Components/InvoiceDownloadButton";

const Invoices = () => {
    const {process: loadInvoices, state: {result:items, isSuccess: invoicesLoaded}} = useModel((model) => model.invoice.get);
    const {accessToken} = useAuth();
    useEffect(() => {
        loadInvoices();
    }, [loadInvoices]);
    return <Table striped bordered hover size="sm">
        <thead>
        <tr>
            <th>Číslo</th>
            <th>Firma</th>
            <th>Cena</th>
            <th/>
        </tr>
        </thead>
        <tbody>
        {items ? items.map((item, index) => {
            const {id, companyName, code, basePrice} = item;
            return <tr key={index}>
                <td>{code}</td>
                <td>{companyName}</td>
                <td className='text-right'><strong>{basePrice}</strong></td>
                <td className='text-right p-0 m-0'>
                    <InvoiceDownloadButton
                        id={id}
                        variant='link'
                        size='lg'
                    ><FileEarmarkArrowDown size={20}/></InvoiceDownloadButton>
                </td>
            </tr>
        }) : null}
        </tbody>
    </Table>;
};

export default Invoices;