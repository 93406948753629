import React from "react";
import Table from "react-bootstrap/Table";

const EvidenceItems = ({items, onSelect}) => {
    return <Table striped bordered hover size="sm">
        <thead className='sticky-top'>
        <tr>
            <th>Název</th>
            <th className='text-right'>Ks</th>
            <th className='text-right'>Cena/ks</th>
            <th className='text-right'>Celkem</th>
        </tr>
        </thead>
        <tbody>
        {items ? items.map((evidenceItem, index) => {
            const {name, unitPrice, amount} = evidenceItem;
            return <tr onClick={() => onSelect ? onSelect(evidenceItem) : null} key={index}>
                <td>{name}</td>
                <td className='text-right'>{amount}</td>
                <td className='text-right'>{unitPrice}</td>
                <td className='text-right'>{amount*unitPrice}</td>
            </tr>
        }) : null}
        </tbody>
    </Table>;
};

export default EvidenceItems;