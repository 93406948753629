import React from "react";
import Table from "react-bootstrap/Table";

const Customers = ({items, onSelect}) => {
    return <Table striped bordered hover size="sm">
        <thead className='sticky-top'>
        <tr>
            <th>Název</th>
            <th>Město</th>
        </tr>
        </thead>
        <tbody>
        {items ? items.map((customer, index) => {
            const {name, city} = customer;
            return <tr onClick={() => onSelect(customer)} key={index}>
                <td>{name}</td>
                <td>{city}</td>
            </tr>
        }) : null}
        </tbody>
    </Table>;
};

export default Customers;