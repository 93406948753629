import React, {useCallback, useContext} from "react";
import {OrderContext} from "../order/OrderContext";
import Button from "react-bootstrap/Button";
import handleClick from "../utils/handleClick";
import {ArrowRight, X} from "react-bootstrap-icons";
import {LinkContainer} from "react-router-bootstrap";

const CustomerChooseButtons = ({pages}) => {

    const {setCustomer, evidenceItems} = useContext(OrderContext);

    const handleResetCustomer = useCallback(() => {
        setCustomer(null);
    }, [setCustomer]);

    return <>
        <Button variant='light' onClick={handleClick(handleResetCustomer)} className='mr-0 mr-md-1'><X /></Button>
    </>;
};

export default CustomerChooseButtons;