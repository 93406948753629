import React, {useCallback, useContext} from "react";
import {OrderContext} from "../order/OrderContext";
import EvidenceItems from "../Components/EvidenceItems";
import {Redirect} from "react-router-dom";
import CurrentEvidenceItem from "../Components/CurrentEvidenceItem";
import useModel from "../model/useModel";
import Button from "react-bootstrap/Button";
import {CardList, CartPlus, CashStack} from "react-bootstrap-icons";
import OrderOverview from "../Components/OrderOverview";
import handleClick from "../utils/handleClick";
import {Spinner} from "react-bootstrap";
import {generatePath} from 'react-router-dom';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {LinkContainer} from "react-router-bootstrap";
import CustomerBar from "../Components/CustomerBar";
import ButtonLabel from "../Components/Layout/ButtonLabel";
import Alert from "react-bootstrap/Alert";

const Order = ({onSelect, pages}) => {
    const {customer, evidenceItems, setCurrentEvidenceItem, currentEvidenceItem, dismissCurrentEvidenceItem, confirmCurrentEvidenceItem} = useContext(OrderContext);
    const {process: saveOrder, state: {result, isFetching: isSaving, isFailed, isSuccess: orderSaved}} = useModel((model) => model.order.save);

    const handleSelectEvidenceItem = useCallback((evidenceItem) => {
        setCurrentEvidenceItem(evidenceItem);
    }, [setCurrentEvidenceItem]);

    const handleOrder = useCallback((paymentMethod) => {
        saveOrder({
            addressBookId: customer.id,
            evidenceItems,
            paymentMethod
        })
    }, [saveOrder, customer, evidenceItems]);

    if (!customer) {
        return <Redirect to={pages.prodejZakaznik.path}/>
    }

    if (evidenceItems.length === 0) {
        return <Redirect to={pages.prodejProdukt.path}/>
    }

    if (isFailed) {
        return <Alert variant={"danger"}>{'Nepodařilo se odeslat objednávku!'}</Alert>;
    }

    if (orderSaved) {
        if (result.invoiceId) {
            return <Redirect to={{pathname: generatePath(pages.prodejDokoncen.path, {invoice: decodeURIComponent(result.invoiceId)}), search: result.message ? "message=" + result.message : null}} />
        } else {
            return <Alert variant={"warning"}>{'Nepodařilo se vytvořit fakturu!'}</Alert>;
        }
    }

    if (isSaving) {
        return <Spinner animation={'border'} />;
    }

    return <>
        {!currentEvidenceItem ? <EvidenceItems items={evidenceItems}
                                               onSelect={handleSelectEvidenceItem}/> : null}

        {currentEvidenceItem ? <CurrentEvidenceItem evidenceItem={currentEvidenceItem}
                                                    onDismiss={dismissCurrentEvidenceItem}
                                                    onConfirm={confirmCurrentEvidenceItem}/> : null}

        <CustomerBar />
        {!currentEvidenceItem ? <Row>
            <Col xs={12} md={6} lg={3}>
                <LinkContainer to={pages.prodejProdukt.path}><Button variant={'warning'} className='mr-2' block={true}><CartPlus/> Přidat</Button></LinkContainer>
            </Col>
        </Row> : null}

        <OrderOverview>
            <Button variant='success' onClick={handleClick(() => handleOrder('code:HOTOVE'))} className='mr-2'>
                <CashStack />
                <span> Hotově</span>
            </Button>
            <Button variant='success' onClick={handleClick(() => handleOrder('code:PREVOD'))}>
                <CardList />
                <span> Převodem</span>
            </Button>
        </OrderOverview>
    </>;
};

export default Order;