import {matchPath, Route, Switch, useLocation, useRouteMatch} from "react-router-dom";
import useAuth from "../../auth/useAuth";
import React, {useContext, useMemo} from "react";
import {OrderContext} from "../../order/OrderContext";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import {LinkContainer} from "react-router-bootstrap";
import Button from "react-bootstrap/Button";
import {House, Search} from "react-bootstrap-icons";
import NavbarBrand from "react-bootstrap/NavbarBrand";
import handleClick from "../../utils/handleClick";
import SignalButtons from "./SignalButtons";

const AppNavbar = ({pages, children}) => {
    const matchHomepage = useRouteMatch();
    const location = useLocation();
    const {username} = useAuth();
    const {toggleCustomerSearch, toggleProductSearch} = useContext(OrderContext);

    const currentPage = useMemo(() => {
        return Object.values(pages).find((item) => {
            return matchPath(location.pathname, item.path)
        });
    }, [location.pathname, pages]);

    return <Navbar variant='dark' bg='dark' fixed='top'>
        <Container>
            <Col className='text-left' xs={9}>
                {currentPage.path !== pages.homepage.path ?  <LinkContainer to={'/'} exact={true}><Button variant={'outline-light'} className='mr-2'><House/></Button></LinkContainer> : null}
                {currentPage.path === pages.homepage.path || currentPage.path === pages.faktury.path  || currentPage.path === pages.sklad.path  || currentPage.path === pages.prodejDokoncen.path ? <NavbarBrand>{currentPage.label}</NavbarBrand> : null}
                <Switch>
                    <Route path='/prodej' exact={false}>
                        <SignalButtons pages={pages}/>
                    </Route>
                </Switch>
            </Col>
            <Col className='text-right' xs={3}>
                <Switch>
                    <Route path={pages.prodejZakaznik.path}>
                        <Button variant='outline-light' onClick={handleClick(toggleCustomerSearch)}><Search/></Button>
                    </Route>
                    <Route path={pages.prodejProdukt.path}>
                        <Button variant='outline-light' onClick={handleClick(toggleProductSearch)}><Search/></Button>
                    </Route>
                </Switch>
                {currentPage.path === pages.homepage.path ? <div className="navbar-brand">
                    {username}
                </div> : null}
            </Col>
        </Container>
    </Navbar>
}

export default AppNavbar;