import React, {useContext, useMemo} from "react";
import {OrderContext} from "../order/OrderContext";
import {Dropdown, DropdownButton} from "react-bootstrap";
import handleClick from "../utils/handleClick";

const ProductsFilter = ({filters, className}) => {
    const {filter, setFilter} = useContext(OrderContext);
    const current = useMemo(() => {
        if (!filter || !filters) {
            return 'Všechny skupiny';
        }
        if (filter === 'NOTLISTED') {
            return 'Nezařazené';
        }
        return filters.find(({id}) => id === filter).name;
    }, [filters, filter]);

    return <DropdownButton
                className={className ? className  : className + ' text-right'}
                size="sm"
                variant='default'
                title={current}
            >
        <Dropdown.Item onClick={handleClick(() => setFilter(null))}>Všechny skupiny</Dropdown.Item>
        <Dropdown.Item onClick={handleClick(() => setFilter('NOTLISTED'))}>Nezařazené</Dropdown.Item>
        {filters ? filters.map(({name, id}) =>  <Dropdown.Item key={id} onClick={handleClick(() => setFilter(id))}>{name}</Dropdown.Item>) : null}
    </DropdownButton>;
};

export default ProductsFilter;