import React, {useCallback, useContext, useEffect, useState} from "react";
import useModel from "../model/useModel";
import Customers from "../Components/Customers";
import {OrderContext} from "../order/OrderContext";
import Spinner from "react-bootstrap/Spinner";
import {useHistory} from "react-router-dom";
import CustomerBar from "../Components/CustomerBar";
import CustomerChooseButtons from "../Components/CustomerChooseButtons";
import useSearch from "../search/useSearch";

const SEARCH_CONFIG = {ref: 'id', fields: ['name', 'city']};

const SaleCustomers = ({pages}) => {
    const {process: loadCustomers, state: {result, isSuccess: customersLoaded}} = useModel((model) => model.customer.get);
    const {setCustomer, searchText, hideSearchCustomer} = useContext(OrderContext);
    const history = useHistory();
    const visibleCustomers = useSearch({documents: result, searchText, config: SEARCH_CONFIG}); // predava se jako konstanta aby se nevytvarel config porad znova pri kazdem pruchodu

    // nacteni zakazniki pri prvnim pruchodu
    useEffect(() => {
        loadCustomers();
    }, [loadCustomers]);

    // zajisti vypnuti vyhledavani pri opusteni komponenty
    useEffect(() => hideSearchCustomer, [hideSearchCustomer]);

    // vybrani zakaznika, presmeruje na kosik
    const handleSelect = useCallback((selected) => {
        if (selected) {
            setCustomer(selected);
            history.push(pages.objednavka.path);
        }
    }, [setCustomer, pages, history]);

    return <>
        <CustomerBar>
            <CustomerChooseButtons pages={pages}/>
        </CustomerBar>
        {customersLoaded ? <Customers items={visibleCustomers} onSelect={handleSelect}/> : <Spinner animation={'border'} />}
    </>;
};

export default SaleCustomers;