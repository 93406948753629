import React, {useContext} from "react";
import {OrderContext} from "../order/OrderContext";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import SearchBar from "./SearchBar";
import {Map} from "react-bootstrap-icons";
import ProductsFilter from "./ProductsFilter";

const CustomerBar = ({children, filters}) => {
    const {customer, visibleSearchCustomer, visibleSearchProduct} = useContext(OrderContext);

    if (!customer && !visibleSearchCustomer && !visibleSearchProduct) {
        return null;
    }
    const {name, companyId, city} = customer || {};
    return <div className='fixed-secondary-top bg-light'>
        <Container>
            {customer ?
                <Row className='nav-bar-row'>
                    <Col xs={7} sm={9} md={9} className='pt-2 pb-2'>
                        <div style={{
                            overflow: "hidden",
                            maxHeight: "1.5em",
                            lineHeight: '1.5em',
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap"
                        }}>{name}, {city}</div>
                    </Col>
                    <Col xs={5} sm={3} md={3} className='text-right'>
                        <div style={{minWidth: '90px'}}>
                        {children}
                        </div>
                    </Col>
                </Row> : null}
            {visibleSearchCustomer || visibleSearchProduct ?
                <Row className='nav-bar-row'>
                    <Col className='pt-1 pb-1'>
                        <SearchBar/>
                    </Col>
                    {/*{visibleSearchProduct && filters ? <Col className='pt-1 pb-1 products-filter' xs={4} md={3} lg={2}><ProductsFilter filters={filters}/></Col> : null}*/}
                </Row> : null}
        </Container>
    </div>
};

export default CustomerBar;